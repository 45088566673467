import React from "react"
import { graphql } from "gatsby"
import {Container, Row, Col} from "reactstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/blogCard"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || `Title`
  const posts = data.blog.edges

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Blog" />
        <p>No blog posts found. Add markdown posts to "content/blog" (or the directory you specified for the "gatsby-source-filesystem" plugin in gatsby-config.js).</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      
      <SEO title="Blog" />

      <Container className="py-5 mt-5">
        
        <Row>

            <Col xs={{'size': 12, 'offset': 0}} sm={{'size': 10, 'offset': 1}}>
                <h2 className="mb-4">Our Blog</h2>
                {posts.map((blogPost) => {
                    const post = blogPost.node
                    return (
                    
                        <BlogCard key={`/blog${post.fields.slug}`} post={post} orientation="landscape" />

                    )

                })}  
            </Col>

        </Row>
      </Container>

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "blog" } } }
      ) {
      edges{
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            subtitle
            posttype
            thumbnail{
              childImageSharp{
                fluid(maxWidth:600, quality:80){
                  ...GatsbyImageSharpFluid
                }
              }
            }
            header{
              childImageSharp{
                fluid(maxWidth:1600, quality:100){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    portfolio: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "portfolio" } } }
      ) {
      edges{
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            subtitle
            posttype
            thumbnail{
              childImageSharp{
                fluid(maxWidth:600, quality:80){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    headerImg: file(relativePath: { eq: "header-v1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    showoff1: file(relativePath: { eq: "showoff1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    showoff2: file(relativePath: { eq: "showoff2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    portfolioItem1: file(relativePath: { eq: "thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`
