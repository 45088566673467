import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const BlogCard = ({ post, orientation }) => {

    const mainImg = (orientation === 'portrait') ? post.frontmatter.thumbnail.childImageSharp.fluid : {...post.frontmatter.header.childImageSharp.fluid, aspectRatio: 16/9}

    return(

        <article
        itemScope
        itemType="http://schema.org/Article"
        className={`blogCard ${orientation}`}
        >
            <header>
                <Link to={`/blog${post.fields.slug}`} itemProp="url" >
                <Img className="blogCard__figure" alt={post.frontmatter.title} fluid={mainImg} />
                </Link>
            </header>
            <section className="blogCard__body">
                <small>{post.frontmatter.subtitle}</small>
                <h3>
                    <Link to={`/blog${post.fields.slug}`} itemProp="url">
                    <span itemProp="headline">{post.frontmatter.title}</span>
                    </Link>
                </h3>
            </section>
        </article>

    )

}

export default BlogCard